import React from 'react';
import { Container, Row, Col, Navbar, Nav, Dropdown } from 'react-bootstrap';

import './home.css';

function Home() {
    //this a draft
    return(<>
        <Navbar style={{ backgroundColor: '#36B25F'}}>
            <Container>
                <Nav className='ml-auto'>
                    <Nav.Item>
                        <img style={{ width: '40px', height: '40px', borderRadius: '20px' }} src='images/eng.png' alt='' />
                    </Nav.Item>
                    <Nav.Item>
                        <Dropdown style={{ lineHeight: '40px', margin: '0 12px', fontWeight: 'bold', color: 'white' }}>
                            <Dropdown.Toggle style={{ backgroundColor: '#36B25F', border: 'none' }} id='dd-lang'>
                                English
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item>English</Dropdown.Item>
                                <Dropdown.Item>Chinese</Dropdown.Item>
                                <Dropdown.Item>Filipino</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Nav.Item>
                </Nav>
                <Nav className='mr-auto'>
                    <Nav.Item>
                        <div style={{ backgroundColor: 'white', height: '40px', borderRadius: '8px' }}>
                            <span style={{ lineHeight: '40px', margin: '24px', fontWeight: 'bold', color: '#36B25F' }}>Sign In</span>
                        </div>
                    </Nav.Item>
                    <Nav.Item>
                        <div style={{ backgroundColor: 'white', width: '40px', height: '40px', margin: '0 12px', borderRadius: '20px',
                            display: 'flex', alignItems: 'center'
                        }}>
                            <img style={{ backgroundColor: 'white', width: '17.5px', height: '17.5px', margin: '0 12px', borderRadius: '20px' }} src='images/headphones.png' alt='' />
                        </div>
                    </Nav.Item>
                    <Nav.Item>
                        <div style={{ backgroundColor: 'white', width: '40px', height: '40px', margin: '0 12px', borderRadius: '20px',
                            display: 'flex', alignItems: 'center'
                        }}>
                            <img style={{ backgroundColor: 'white', width: '17.5px', height: '17.5px', margin: '0 12px', borderRadius: '20px' }} src='images/bell.png' alt='' />
                        </div>
                    </Nav.Item>
                </Nav>
            </Container>
        </Navbar>
        <Navbar style={{ padding: 0 }}>
            <Container>
                <Nav className='ml-auto'>
                    <Nav.Item>
                        <img src='/images/MorningStar.png' alt='' />
                    </Nav.Item>
                </Nav>
                <Nav className='mr-auto'>
                    <Nav.Item className='nav2-item selected'>
                        <img style={{ width: '16px', marginBottom: '4px' }} src='/images/all.png' alt='' />
                        All
                    </Nav.Item>
                    <Nav.Item className='nav2-item'>
                        <img className='nav-item-icon' src='/images/LiangPi.png' alt='' />
                        LiangPi
                    </Nav.Item>
                    <Nav.Item className='nav2-item'>
                        <img className='nav-item-icon' src='/images/Roujiamo.png' alt='' />
                        Roujiamo
                    </Nav.Item>
                    <Nav.Item className='nav2-item'>
                        <img className='nav-item-icon' src='/images/Noodles.png' alt='' />
                        Noodles
                    </Nav.Item>
                    <Nav.Item className='nav2-item'>
                        <img className='nav-item-icon' src='/images/FriedRice.png' alt='' />
                        Fried Rice
                    </Nav.Item>
                    <Nav.Item className='nav2-item'>
                        <img className='nav-item-icon' src='/images/Dumplings.png' alt='' />
                        Dumplings
                    </Nav.Item>
                    <Nav.Item className='nav2-item'>
                        <img className='nav-item-icon' src='/images/Wraps.png' alt='' />
                        Wraps
                    </Nav.Item>
                    <Nav.Item className='nav2-item'>
                        <img className='nav-item-icon' src='/images/Tangbao.png' alt='' />
                        Tangbao
                    </Nav.Item>
                    <Nav.Item className='nav2-item'>
                        <img className='nav-item-icon' src='/images/Drinks.png' alt='' />
                        Drinks
                    </Nav.Item>
                </Nav>
            </Container>
        </Navbar>
        <Container className='main-content'>
            <Row className='featured-container' style={{ backgroundImage: 'url(/images/featured.png)' }}>
            </Row>
            <Row style={{ margin: 0 }}>
                <div className='featured-title'>
                    <img style={{ width: '24px', height: '24px' }} src='/images/Announcement.png' alt='' />
                    <span>5/25/2023 19:13:49 opening</span>
                </div>
            </Row>
            <Row className='filters'>
                <Col>
                    <span style={{ float: 'left', marginLeft: '40px', lineHeight: '48px', color: '#FF6868', fontWeight: 'bold' }}>Special Dishes</span>
                </Col>
                <Col>
                    <img className='filter-icons' src='/images/next.png' alt='' />
                    <img className='filter-icons' src='/images/previous.png' alt='' />
                    <img className='filter-search' src='/images/search.png' alt='' />{/* placeholder */}
                    <img className='filter-icons' src='/images/filter.png' alt='' />
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col xs={4} className='item'>
                    <Container style={{ boxShadow: '0 0 3px #EEEEEE', borderRadius: '32px', position: 'relative', paddingBottom: '20px' }}>
                        <Row><img style={{ width: '80%', margin: '20px auto' }} src='/images/cardImgs/pic1.png' alt='' /></Row>
                        <Row><span style={{ fontWeight: 'bold' }}>Fattoush salad</span></Row>
                        <Row><span style={{ color: 'grey' }}>Description of the item</span></Row>
                        <Row>
                            <Col><span style={{ float: 'left', fontWeight: 'bold', color: '#FF6868' }}>$</span><span style={{ float: 'left', fontWeight: 'bold' }}>4.00</span></Col>
                            <Col><span style={{ paddingLeft: '8px', float: 'right' }}>4.9</span><img style={{ float: 'right' }} src='/images/cardImgs/star.png' alt='' /></Col>
                        </Row>
                        <img style={{ position: 'absolute', top: 0, right: 0, width: '52px', height: '46px' }} src='/images/cardImgs/favBg.png' alt='' />
                        <img style={{ position: 'absolute', top: '12px', right: '15px' }} src='/images/cardImgs/heartAct.png' alt='' />
                    </Container>
                </Col>
                <Col xs={4} className='item'>
                    <Container style={{ boxShadow: '0 0 3px #EEEEEE', borderRadius: '32px', position: 'relative', paddingBottom: '20px' }}>
                        <Row><img style={{ width: '80%', margin: '20px auto' }} src='/images/cardImgs/pic2.png' alt='' /></Row>
                        <Row><span style={{ fontWeight: 'bold' }}>Vegetable salad</span></Row>
                        <Row><span style={{ color: 'grey' }}>Description of the item</span></Row>
                        <Row>
                            <Col><span style={{ float: 'left', fontWeight: 'bold', color: '#FF6868' }}>$</span><span style={{ float: 'left', fontWeight: 'bold' }}>6.00</span></Col>
                            <Col><span style={{ paddingLeft: '8px',  float: 'right' }}>4.6</span><img style={{ float: 'right' }} src='/images/cardImgs/star.png' alt='' /></Col>
                        </Row>
                        <img style={{ position: 'absolute', top: 0, right: 0, width: '52px', height: '46px' }} src='/images/cardImgs/favBg.png' alt='' />
                        <img style={{ position: 'absolute', top: '12px', right: '15px' }} src='/images/cardImgs/heartDef.png' alt='' />
                    </Container>
                </Col>
                <Col xs={4} className='item'>
                    <Container style={{ boxShadow: '0 0 3px #EEEEEE', borderRadius: '32px', position: 'relative', paddingBottom: '20px' }}>
                        <Row><img style={{ width: '80%', margin: '20px auto' }} src='/images/cardImgs/pic3.png' alt='' /></Row>
                        <Row><span style={{ fontWeight: 'bold' }}>Egg Vegi Salad</span></Row>
                        <Row><span style={{ color: 'grey' }}>Description of the item</span></Row>
                        <Row>
                            <Col><span style={{ float: 'left', fontWeight: 'bold', color: '#FF6868' }}>$</span><span style={{ float: 'left', fontWeight: 'bold' }}>3.00</span></Col>
                            <Col><span style={{ paddingLeft: '8px', float: 'right' }}>4.5</span><img style={{ float: 'right' }} src='/images/cardImgs/star.png' alt='' /></Col>
                        </Row>
                        <img style={{ position: 'absolute', top: 0, right: 0, width: '52px', height: '46px' }} src='/images/cardImgs/favBg.png' alt='' />
                        <img style={{ position: 'absolute', top: '12px', right: '15px' }} src='/images/cardImgs/heartDef.png' alt='' />
                    </Container>
                </Col>
            </Row>
            <Row>
                <Col xs={2} className='mt-5'>
                    <Container className='item-small-container text-center' style={{ position: 'relative' }}>
                        <Row className='item-small-img-container'><img className='item-small-img' src='/images/smallCardImgs/sm1.png' alt='' /></Row>
                        <Row><span style={{ fontWeight: 'bold' }}>Fattoush Salad</span></Row>
                        <Row><span style={{ fontSize: '12px', marginBottom: '12px' }}>Description of the item</span></Row>
                        <img style={{ position: 'absolute', top: 0, right: 0, width: '20px', height: '17px' }} src='/images/cardImgs/favBg.png' alt='' />
                        <img style={{ position: 'absolute', top: '4px', right: '5px', width: '10px', height: '10px' }} src='/images/cardImgs/heartDef.png' alt='' />
                    </Container>
                </Col>
                <Col xs={2} className='mt-5'>
                    <Container className='item-small-container text-center' style={{ position: 'relative' }}>
                        <Row className='item-small-img-container'><img className='item-small-img' src='/images/smallCardImgs/sm2.png' alt='' /></Row>
                        <Row><span style={{ fontWeight: 'bold' }}>Fattoush Salad</span></Row>
                        <Row><span style={{ fontSize: '12px', marginBottom: '12px' }}>Description of the item</span></Row>
                        <img style={{ position: 'absolute', top: 0, right: 0, width: '20px', height: '17px' }} src='/images/cardImgs/favBg.png' alt='' />
                        <img style={{ position: 'absolute', top: '4px', right: '5px', width: '10px', height: '10px' }} src='/images/cardImgs/heartDef.png' alt='' />
                    </Container>
                </Col>
                <Col xs={2} className='mt-5'>
                    <Container className='item-small-container text-center' style={{ position: 'relative' }}>
                        <Row className='item-small-img-container'><img className='item-small-img' src='/images/smallCardImgs/sm3.png' alt='' /></Row>
                        <Row><span style={{ fontWeight: 'bold' }}>Fattoush Salad</span></Row>
                        <Row><span style={{ fontSize: '12px', marginBottom: '12px' }}>Description of the item</span></Row>
                        <img style={{ position: 'absolute', top: 0, right: 0, width: '20px', height: '17px' }} src='/images/cardImgs/favBg.png' alt='' />
                        <img style={{ position: 'absolute', top: '4px', right: '5px', width: '10px', height: '10px' }} src='/images/cardImgs/heartDef.png' alt='' />
                    </Container>
                </Col>
                <Col xs={2} className='mt-5'>
                    <Container className='item-small-container text-center' style={{ position: 'relative' }}>
                        <Row className='item-small-img-container'><img className='item-small-img' src='/images/smallCardImgs/sm4.png' alt='' /></Row>
                        <Row><span style={{ fontWeight: 'bold' }}>Fattoush Salad</span></Row>
                        <Row><span style={{ fontSize: '12px', marginBottom: '12px' }}>Description of the item</span></Row>
                        <img style={{ position: 'absolute', top: 0, right: 0, width: '20px', height: '17px' }} src='/images/cardImgs/favBg.png' alt='' />
                        <img style={{ position: 'absolute', top: '4px', right: '5px', width: '10px', height: '10px' }} src='/images/cardImgs/heartDef.png' alt='' />
                    </Container>
                </Col>
                <Col xs={2} className='mt-5'>
                    <Container className='item-small-container text-center' style={{ position: 'relative' }}>
                        <Row className='item-small-img-container'><img className='item-small-img' src='/images/smallCardImgs/sm5.png' alt='' /></Row>
                        <Row><span style={{ fontWeight: 'bold' }}>Fattoush Salad</span></Row>
                        <Row><span style={{ fontSize: '12px', marginBottom: '12px' }}>Description of the item</span></Row>
                        <img style={{ position: 'absolute', top: 0, right: 0, width: '20px', height: '17px' }} src='/images/cardImgs/favBg.png' alt='' />
                        <img style={{ position: 'absolute', top: '4px', right: '5px', width: '10px', height: '10px' }} src='/images/cardImgs/heartDef.png' alt='' />
                    </Container>
                </Col>
                <Col xs={2} className='mt-5'>
                    <Container className='item-small-container text-center' style={{ position: 'relative' }}>
                        <Row className='item-small-img-container text-center'><img className='item-small-img' src='/images/smallCardImgs/sm6.png' alt='' /></Row>
                        <Row><span style={{ fontWeight: 'bold' }}>Fattoush Salad</span></Row>
                        <Row><span style={{ fontSize: '12px', marginBottom: '12px' }}>Description of the item</span></Row>
                        <img style={{ position: 'absolute', top: 0, right: 0, width: '20px', height: '17px' }} src='/images/cardImgs/favBg.png' alt='' />
                        <img style={{ position: 'absolute', top: '4px', right: '5px', width: '10px', height: '10px' }} src='/images/cardImgs/heartDef.png' alt='' />
                    </Container>
                </Col>
            </Row>
            <Row className='mt-5 pt-1' style={{ borderTop: '1px solid #EEEEEE', color: '#555555' }}>
                <Col xs={3}>
                    <Container>
                        <Row><img style={{ maxWidth: '160px' }} src='/images/MorningStar.png' alt='' /></Row>
                        <Row className='mt-3'>Savor the artistry where every dish is a culinary masterpiece</Row>
                    </Container>
                </Col>
                <Col xs={3}>
                    <Container>
                        <Row style={{ fontWeight: 'bold', color: 'black' }}>Useful Links</Row>
                        <Row className='mt-3'>About Us</Row>
                        <Row>Events</Row>
                        <Row>Blogs</Row>
                        <Row>FAQ</Row>
                    </Container>
                </Col>
                <Col xs={3}>
                    <Container>
                        <Row style={{ fontWeight: 'bold', color: 'black' }}>Main Menu</Row>
                        <Row className='mt-3'>Home</Row>
                        <Row>Offers</Row>
                        <Row>Menus</Row>
                        <Row>Reservations</Row>
                    </Container>
                </Col>
                <Col xs={3}>
                    <Container>
                        <Row style={{ fontWeight: 'bold', color: 'black' }}>Contact Us</Row>
                        <Row className='mt-3'>morningstarcuisine@gmail.com</Row>
                        <Row>+63 968 761 8666</Row>
                        <Row>www.morningstarcuisine.com</Row>
                    </Container>
                </Col>
            </Row>
            <Row className='mt-5' style={{ borderTop: '1px solid #EEEEEE', color: '#555555' }}>
                <p className='mt-3'>Morningstar Restaurant is a culinary gem that combines the artistry of Chinese cuisine with a dedication to health and sustainability. Our restaurant is characterized by its commitment to offering a diverse menu of dishes that cater to various dietary preferences, with a strong focus on providing delicious and nourishing options. We take pride in the fusion of flavors, the quality of ingredients, and the dining experience we offer.</p>
            </Row>
            <Row className='mt-5 mb-5' style={{ borderTop: '1px solid #EEEEEE', color: '#555555' }}>
                <Container className='mt-3'>
                    <Row>
                        <Col>
                            <img src='/images/paymentOpts.png' alt='' />
                        </Col>
                        <Col style={{ alignContent: 'center' }}>
                            <Row>
                                <Col>
                                    <div style={{ backgroundColor: '#36B25F', borderRadius: '20px', width: '40px', height: '40px', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                        <img style={{ width: '18px', height: '29px' }} src='/images/icon_fb.png' alt='' />
                                    </div>
                                </Col>
                                <Col>
                                    <div style={{ backgroundColor: '#36B25F', borderRadius: '20px', width: '40px', height: '40px', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                        <img style={{ width: '26px', height: '26px' }} src='/images/icon_ig.png' alt='' />
                                    </div>
                                </Col>
                                <Col>
                                    <div style={{ backgroundColor: '#36B25F', borderRadius: '20px', width: '40px', height: '40px', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                        <img style={{ width: '30px', height: '25px' }} src='/images/icon_x.png' alt='' />
                                    </div>
                                </Col>
                                <Col>
                                    <div style={{ backgroundColor: '#36B25F', borderRadius: '20px', width: '40px', height: '40px', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                        <img style={{ width: '31px', height: '23px' }} src='/images/icon_yt.png' alt='' />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    
                </Container>
            </Row>
            <Row className='text-center mb-5' style= {{ color: '#555555' }}>
                <span>Copyright © 2023 Dscode | All rights reserved</span>
            </Row>
        </Container>
    </>);
}

export default Home;